import classNames from "classnames"
import { AnimatePresence, motion } from "framer-motion"
import type { ReactNode } from "react"

export type SheetProps = {
  isOpen: boolean
  children?: ReactNode
  className?: string
}

export default function Sheet(props: SheetProps) {
  return (
    <AnimatePresence>
      <motion.div
        className={classNames(
          "hidden flex-col fixed w-screen h-dvh backdrop-blur bg-white/75 top-[100vh] overflow-auto overscroll-contain z-10",
          props.className
        )}
        animate={props.isOpen ? { top: 0, display: "flex" } : {}}
        transition={{ ease: [0, 0, 0.205, 1.005] }}
        exit={{ top: "100vh", transitionEnd: { display: "none" } }}
      >
        {props.children}
      </motion.div>
    </AnimatePresence>
  )
}

export function BottomSheet(props: SheetProps & { onClose: () => void }) {
  return (
    <AnimatePresence>
      <motion.div
        className="hidden fixed top-0 l-0 h-screen w-screen backdrop-blur-sm bg-gray-600/20 z-40"
        onClick={props.onClose}
        animate={props.isOpen ? { display: "block", opacity: 1 } : { opacity: 0 }}
        exit={{ opacity: 0, transitionEnd: { display: "none" } }}
        key="backdrop"
      />

      <motion.div
        className={classNames(
          "hidden flex-col fixed w-screen h-[40vh] backdrop-blur bg-white/75 overflow-auto top-[100vh] overscroll-contain rounded-t-3xl z-50 shadow-2xl",
          props.className
        )}
        animate={props.isOpen ? { top: "60vh", display: "flex" } : {}}
        transition={{ ease: [0, 0, 0.205, 1.005] }}
        exit={{ top: "100vh", transitionEnd: { display: "none" } }}
        key="sheet"
      >
        {props.children}
      </motion.div>
    </AnimatePresence>
  )
}

import EmptyState from "@/components/empty-state"
import type { AppError } from "@/utils/errors"
import ErrorIcon from "@mui/icons-material/Error"
import type { ReactNode } from "react"

export default function ErrorState(props: {
  icon?: ReactNode
  error?: Error
  title?: string
  subtitle?: string
}) {
  const { icon, error } = props
  const appError = error as AppError
  const title = props.title ?? appError?.userSummary ?? "An error occurred"
  const subtitle =
    props.subtitle ?? appError.userSummary ? appError?.userMessage : "Please try again later"

  return (
    <EmptyState
      icon={icon ?? <ErrorIcon className="text-red-600" fontSize="large" />}
      title={title}
      subtitle={subtitle}
    />
  )
}

export class AppError extends Error {
  public readonly userSummary: string | undefined
  public readonly userMessage: string | undefined

  constructor(
    message: string,
    options?: { cause?: unknown; userSummary?: string; userMessage?: string }
  ) {
    super(message, { cause: options?.cause })
    this.userSummary = options?.userSummary
    this.userMessage = options?.userMessage
  }
}

export class UnauthenticatedError extends AppError {
  constructor(message?: string) {
    super(message ?? "User not authenticated", { userSummary: "Please sign in to continue." })
  }
}
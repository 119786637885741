import { app } from "@/services/firebase.ts"
import type { Part } from "@google-cloud/vertexai"
import { Schema, getGenerativeModel, getVertexAI } from "firebase/vertexai"

const vertexAi = getVertexAI(app)

async function fileToGenerativePart(file: File): Promise<Part> {
  const base64EncodedDataPromise = new Promise(resolve => {
    const reader = new FileReader()
    reader.onloadend = () => {
      if (typeof reader.result === "string") resolve(reader.result.split(",")[1])
    }
    reader.readAsDataURL(file)
  })

  return {
    inlineData: {
      data: (await base64EncodedDataPromise) as string,
      mimeType: file.type
    }
  }
}

function getModel(options?: { schema: Schema }) {
  return getGenerativeModel(vertexAi, {
    model: "gemini-1.5-flash",
    generationConfig: options?.schema && {
      responseMimeType: "application/json",
      responseSchema: options?.schema
    }
  })
}

export async function runPromptWithImage(
  prompt: string,
  imageFile: File,
  options?: { schema: Schema }
) {
  const model = getModel(options)
  const imagePart = await fileToGenerativePart(imageFile)

  const result = await model.generateContent([prompt, imagePart])
  return result.response.text()
}

export async function runPrompt(prompt: string) {
  const result = await getModel().generateContent(prompt)

  const response = result.response
  return response.text()
}

export { Schema }

import Sheet, { type SheetProps } from "@/components/sheet.tsx"
import { Switch } from "@/components/switch"
import { useDialog } from "@/hooks/use-dialog.tsx"
import { useLocalStorage } from "@/hooks/use-local-storage"
import { useAuth } from "@/services/auth-service"
import { addExpenseMember, removeExpenseMember } from "@/services/expense-service.ts"
import {
  useFriends,
  useUserProfiles,
  userProfilesCollection
} from "@/services/user-profile-service.ts"
import { addFriend } from "@/services/user-service.ts"
import type { CreateUserProfileDto, Expense, ExpenseItem } from "@/types.ts"
import { Check, ChevronLeft } from "@mui/icons-material"
import { partition } from "lodash"
import {
  type ChangeEvent,
  type MouseEvent,
  type ReactNode,
  useCallback,
  useMemo,
  useState
} from "react"

export default function UserManagementScreen(
  props: SheetProps & { onClose: () => void; expense: Expense; items: ExpenseItem[] }
) {
  const [isAddMemberScreenOpen, setIsAddMemberScreenOpen] = useState(false)
  const { data: friendProfiles } = useFriends()
  const { data: memberProfiles } = useUserProfiles(props.expense.memberIds)
  const confirmRemoveDialog = useDialog()
  const {
    isUserPaletteEnabled,
    setIsUserPaletteEnabled,
    recentlyUsedMemberIds,
    addRecentlyUserMemberId
  } = useLocalStorage()
  const currentUserId = useAuth().user?.uid

  const handleUserClicked = async (user: User) => {
    if (user.id === currentUserId) return

    const isRemovingMember = props.expense.memberIds.includes(user.id)
    if (isRemovingMember) {
      await removeExpenseMemberWithConfirmation(user)
    } else {
      await addExpenseMember(props.expense.id, user.id)
      addRecentlyUserMemberId(user.id)
    }
  }

  const friendNotMembers = (friendProfiles ?? []).filter(
    user => !props.expense.memberIds.includes(user.id)
  )
  const [recentFriendsNotAdded, otherFriends] = partition(friendNotMembers, friend => {
    return recentlyUsedMemberIds.includes(friend.id)
  })

  const friends = [...recentFriendsNotAdded, ...otherFriends]
  const hasUnAddedFriends = friends.length > 0

  const memberGroups = [
    {
      title: "Current tab members",
      members: memberProfiles ?? []
    },
    ...(hasUnAddedFriends ? [{ title: "Your friends", members: friends }] : [])
  ]

  const removeExpenseMemberWithConfirmation = async (user: User) => {
    const userItemCount = props.items.filter(item => item.userIds?.includes(user.id)).length
    if (userItemCount === 0) {
      return removeExpenseMember(props.expense.id, user.id)
    }

    const s = userItemCount === 1 ? "" : "s"
    confirmRemoveDialog.open({
      title: `Remove ${user.firstName}?`,
      message: `They will be removed from ${userItemCount} item${s}.`,
      confirmLabel: "Remove",
      onConfirm: () => removeExpenseMember(props.expense.id, user.id)
    })
  }

  const handleAddMemberClick = () => {
    setIsAddMemberScreenOpen(true)
  }

  const handleShowTabMembersPaletteClick = () => {
    setIsUserPaletteEnabled(!isUserPaletteEnabled)
  }

  return (
    <Sheet {...props}>
      <div className="flex justify-between py-4 px-2 border-b-[0.33px] border-black/30 sticky top-0 backdrop-blur bg-white/75 z-10">
        <button type="button" onClick={props.onClose}>
          <ChevronLeft /> Back
        </button>

        <h1>Manage tab members</h1>

        <button type="button" onClick={handleAddMemberClick}>
          Add member
        </button>
      </div>

      <div className="flex-grow pt-4 pb-16 bg-[#F2F2F7]">
        {memberGroups.map(group => (
          <div key={group.title}>
            <List title={group.title}>
              {group.members.map(profile => (
                <ListItem
                  isChecked={props.expense.memberIds.includes(profile.id)}
                  value={profile}
                  key={profile.id}
                  onClick={() => handleUserClicked(profile)}
                >
                  <div className="flex items-center gap-2">
                    <span>
                      {profile.firstName} {profile.lastName}
                    </span>
                    {currentUserId && profile.id === currentUserId && (
                      <span className="text-gray-400 text-sm">(you)</span>
                    )}
                  </div>
                </ListItem>
              ))}
            </List>
          </div>
        ))}

        <div className="mt-10">
          <List title="Settings">
            <ListItem
              isToggled={isUserPaletteEnabled ?? false}
              onToggle={handleShowTabMembersPaletteClick}
            >
              Enable members palette
            </ListItem>
          </List>
        </div>
      </div>

      <AddMemberScreen
        expense={props.expense}
        isOpen={isAddMemberScreenOpen}
        onClose={() => setIsAddMemberScreenOpen(false)}
      />

      {confirmRemoveDialog.render()}
    </Sheet>
  )
}

function List(props: { title?: string; children: ReactNode }) {
  return (
    <ul className="mx-4 pt-1 pb-4">
      <div className="text-gray-400 mb-2">{props.title}</div>
      <div>{props.children}</div>
    </ul>
  )
}

function ListItem<TVal = undefined>(props: {
  children: ReactNode
  isChecked?: boolean
  isToggled?: boolean
  value?: TVal
  onClick?: (value: TVal, evt: MouseEvent) => void
  onToggle?: (isToggled: boolean) => void
}) {
  const handleClick = useCallback(
    (evt: MouseEvent) => {
      props.onClick?.(props.value as TVal, evt)
      props.onToggle?.(!props.isToggled)
    },
    [props.onClick, props.value, props.isToggled, props.onToggle]
  )

  return (
    <li
      className="flex items-center justify-between gap-2 px-4 py-2 border-b last:border-b-0 cursor-pointer bg-white first:rounded-t-md last:rounded-b-md"
      onClick={handleClick}
    >
      {props.children}
      {props.isChecked && <Check className="text-blue-700" />}
      {props.onToggle && <Switch checked={props.isToggled} onChange={props.onToggle} />}
    </li>
  )
}

interface User {
  id: string
  firstName: string
  lastName: string
}

function AddMemberScreen(props: SheetProps & { expense: Expense; onClose: () => void }) {
  const { addRecentlyUserMemberId } = useLocalStorage()
  const currentUserId = useAuth().user?.uid
  const [newMember, updateNewMember, , resetNewMember] = useStateObject<
    Omit<CreateUserProfileDto, "id">
  >({
    firstName: "",
    lastName: "",
    imageUrl: null,
    claimed: false,
    createdBy: "1"
  })

  const handleSubmitClick = async () => {
    const createdProfile = await userProfilesCollection.create(newMember)
    await addFriend(createdProfile.id, currentUserId)
    await addExpenseMember(props.expense.id, createdProfile.id)
    addRecentlyUserMemberId(createdProfile.id)
    props.onClose()
    resetNewMember()
  }

  return (
    <Sheet {...props}>
      <div className="flex justify-between py-4 px-2 border-b-[0.33px] border-black/30">
        <button type="button" onClick={props.onClose}>
          <ChevronLeft /> Cancel
        </button>

        <h1>Add new friend</h1>

        <button type="button" className="font-bold" onClick={handleSubmitClick}>
          Add
        </button>
      </div>

      <div className="bg-white flex-grow">
        <List>
          <TextFieldListItem
            label="First name"
            value={newMember.firstName.toString()}
            onChange={firstName => updateNewMember({ firstName })}
          />
          <TextFieldListItem
            label="Last name"
            value={newMember.lastName.toString()}
            onChange={lastName => updateNewMember({ lastName })}
          />
          {/*<TextFieldListItem label="Phone #" value={"Aimee"} />*/}
        </List>
      </div>
    </Sheet>
  )
}

function TextFieldListItem(props: {
  label: string
  value: string
  onChange: (value: string) => void
}) {
  const fieldId = useMemo(() => `field-${Math.random()}`, [])
  const handleChange = useCallback(
    (evt: ChangeEvent<HTMLInputElement>) => props.onChange(evt.target.value),
    [props.onChange]
  )

  return (
    <ListItem>
      <label htmlFor={fieldId} className="w-[100px]">
        {props.label}
      </label>
      <input
        className="grow h-10 pl-2 mr-2"
        id={fieldId}
        value={props.value}
        onChange={handleChange}
        autoComplete="off"
      />
    </ListItem>
  )
}

function useStateObject<T>(initialValue: T) {
  const [value, setValue] = useState(initialValue)

  const updateValue = useCallback((updates: Partial<T>) => {
    setValue(val => ({ ...val, ...updates }))
  }, [])

  const reset = useCallback(() => setValue(initialValue), [initialValue])

  return [value, updateValue, setValue, reset] as const
}

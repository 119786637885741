import { type DependencyList, useEffect, useState } from "react"

export default function useMemoPromise<T>(get: () => Promise<T>, deps: DependencyList) {
  const [value, setValue] = useState<T | null>(null)
  const [error, setError] = useState<Error | null>(null)

  useEffect(() => {
    get().then(setValue).catch(setError)
  }, deps)

  return { data: value, error }
}

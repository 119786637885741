import type {
  CreateDto,
  CreateExpenseDto,
  CreateExpenseItemDto,
  Expense,
  UserProfile
} from "@/types"
import { initializeApp } from "firebase/app"
import { getAuth } from "firebase/auth"
import {
  type CollectionReference,
  type DocumentData,
  type Timestamp,
  type WithFieldValue,
  addDoc,
  collection,
  doc,
  getDoc,
  getFirestore,
  serverTimestamp,
  writeBatch
} from "firebase/firestore"
import { omit } from "lodash"

const firebaseConfig = {
  apiKey: "AIzaSyAgpl6NY-YgAgWBwzNgrocncfQmvk6d6xw",
  authDomain: "snaptab-a283a.firebaseapp.com",
  projectId: "snaptab-a283a",
  storageBucket: "snaptab-a283a.appspot.com",
  messagingSenderId: "872221403179",
  appId: "1:872221403179:web:e7ee40953b5337171c626e",
  measurementId: "G-HRSXZ43FZZ"
}

export const app = initializeApp(firebaseConfig)
export const db = getFirestore(app)
export const auth = getAuth(app)

export const expensesCollection = collection(db, "expenses")

export async function saveExpense(
  expense: CreateExpenseDto,
  items: CreateExpenseItemDto[]
): Promise<Expense> {
  const batch = writeBatch(db)
  const expenseRef = doc(expensesCollection)
  const { id } = expenseRef
  const data = {
    ...omit(expense, "id"),
    createdAt: serverTimestamp(),
    updatedAt: serverTimestamp()
  }
  batch.set(expenseRef, data)

  const itemsCollection = collection(db, `expenses/${id}/items`)
  for (const item of items) {
    const itemRef = doc(itemsCollection)
    batch.set(itemRef, item)
  }

  await batch.commit()

  const createdExpense = (await getDoc(expenseRef)).data() as Expense
  return { ...createdExpense, id }
}

export interface BaseAppModel extends DocumentData {
  id: string
}

export type DbModelFor<AppModel extends DocumentData> = {
  [K in keyof AppModel]: AppModel[K] extends Date ? Timestamp : AppModel[K] | DocumentData
}

export class BaseCollection<TAppModel extends BaseAppModel> {
  public readonly ref: CollectionReference<TAppModel, DbModelFor<TAppModel>>
  private cache: Map<string, Promise<TAppModel | null>>

  constructor(name: string) {
    this.ref = collection(db, name) as CollectionReference<TAppModel, DbModelFor<TAppModel>>
    this.cache = new Map()
  }

  public async get(...ids: string[]): Promise<TAppModel[]> {
    const promises = ids.map(id => {
      if (!this.cache.has(id)) {
        this.cache.set(id, this.fetchDoc(id))
      }
      return this.cache.get(id)
    })
    const docs = await Promise.all(promises)
    return docs.filter(Boolean) as TAppModel[]
  }

  private async fetchDoc(id: string): Promise<TAppModel | null> {
    console.debug("Fetching doc", `${this.ref.path}/${id}`)
    const snapshot = await getDoc(this.getRef(id))
    return { id: snapshot.id, ...snapshot.data() } as TAppModel
  }

  public getRef(id: string) {
    return doc(this.ref, id)
  }

  public async create(dto: CreateDto<TAppModel>) {
    const data = {
      ...omit(dto, "id"),
      createdAt: serverTimestamp() as Timestamp,
      updatedAt: serverTimestamp() as Timestamp
    }
    const ref = await addDoc(this.ref, data as WithFieldValue<TAppModel>)
    return { ...(data as UserProfile), id: ref.id }
  }
}

import { Button } from "@/components/button"
import EmptyState from "@/components/empty-state.tsx"
import ErrorState from "@/components/error-state"
import PageHeader from "@/components/page-header"
import { BottomSheet } from "@/components/sheet"
import Tabs from "@/components/tabs"
import type { AsyncTask } from "@/hooks/use-async-task"
import { useCreateExpense, useExpenses } from "@/services/expense-service"
import type { Expense } from "@/types"
import { toCurrency } from "@/utils/string-utils"
import ImageNotSupportedIcon from "@mui/icons-material/ImageNotSupported"
import { type Variants, motion } from "framer-motion"
import { orderBy, partition } from "lodash"
import { DateTime } from "luxon"
import { type ChangeEvent, useRef } from "react"
import { Link, useNavigate, useSearchParams } from "react-router-dom"

export default function HomePage() {
  const navigate = useNavigate()
  const createExpense = useCreateExpense()
  const { expenses } = useExpenses()

  const sortedExpenses = orderBy(expenses ?? [], "createdAt", "desc")
  const recentDate = DateTime.now().minus({ weeks: 2 }).toSeconds()
  const [recentExpenses, olderExpenses] = partition(
    sortedExpenses,
    expense => expense.createdAt?.seconds > recentDate
  )

  const handleUpload = async (file: File) => {
    const expense = await createExpense.run(file)
    if (expense) {
      navigate(`/expense/${expense.id}`)
    }
  }

  const tabs = ["Recent", "Older"]
  const [searchParams] = useSearchParams()
  const activeTab = searchParams.get("tab") ?? tabs[0]

  return (
    <>
      <div className="min-h-full w-full flex flex-col relative">
        <PageHeader name="My Snaptabs" subHeader={<Tabs activeTab={activeTab} tabs={tabs} />} />

        {activeTab === "Recent" && <ExpensesList expenses={recentExpenses} />}
        {activeTab === "Older" && <ExpensesList expenses={olderExpenses} />}

        <div className="flex flex-col gap-8 w-full px-4 relative pb-24">
          <UploadReceiptButton onUpload={handleUpload} disabled={!!createExpense.progress} />
        </div>
      </div>

      <UploadingSheet task={createExpense} />
    </>
  )
}

function UploadingSheet(props: { task: AsyncTask }) {
  const { error, progress, cancel } = props.task

  return (
    <BottomSheet isOpen={!!progress} className="flex justify-center items-center" onClose={cancel}>
      {error ? (
        <ErrorState
          error={error}
          icon={<ImageNotSupportedIcon sx={{ fontSize: 48 }} className="text-orange-500" />}
        />
      ) : (
        <EmptyState progress title={progress?.title ?? ""} subtitle={progress?.subtitle ?? ""} />
      )}
    </BottomSheet>
  )
}

const containerVariants: Variants = {
  show: {
    transition: {
      stiffness: 50,
      staggerChildren: 0.04
    }
  }
}

const itemVariants: Variants = {
  hidden: { opacity: 0, y: 15 },
  show: { opacity: 1, y: 0 }
}

function ExpensesList(props: { expenses: Expense[] }) {
  return (
    <motion.div
      className="flex flex-col py-2 px-4 divide-y divide-gray-100"
      initial="hidden"
      animate="show"
      variants={containerVariants}
      key={props.expenses[0]?.id}
    >
      {props.expenses.map(expense => (
        <ExpenseListItem expense={expense} key={expense.id} />
      ))}
    </motion.div>
  )
}

function ExpenseListItem(props: { expense: Expense }) {
  const { id, name, createdAt, total } = props.expense
  const date = DateTime.fromJSDate(createdAt.toDate())
  const isThisYear = date.year === DateTime.now().year
  const dateFormat = { ...DateTime.DATE_MED_WITH_WEEKDAY }
  if (isThisYear) {
    dateFormat.year = undefined
  }

  return (
    <motion.div variants={itemVariants}>
      <Link to={`/expense/${id}`} className="flex justify-between py-2">
        <span className="flex flex-col">
          <span className="font-semibold text-gray-900">{name}</span>
          {createdAt && (
            <span className="text-xs/5 text-gray-500">
              {DateTime.fromJSDate(createdAt.toDate()).toLocaleString({
                ...DateTime.DATE_MED_WITH_WEEKDAY,
                year: undefined
              })}
            </span>
          )}
        </span>

        <span className="flex items-center">{toCurrency(total)}</span>
      </Link>
    </motion.div>
  )
}

function UploadReceiptButton(props: { disabled?: boolean; onUpload: (file: File) => void }) {
  const fileInputRef = useRef<HTMLInputElement | null>(null)
  const handleClick = async (evt: ChangeEvent<HTMLInputElement>) => {
    const file = evt.target.files?.[0]
    if (!file) return

    props.onUpload(file)
  }

  return (
    <div className="fixed bottom-0 left-0 w-full flex p-4 backdrop-blur bg-white/75 border-t border-gray-50">
      <Button
        onClick={() => fileInputRef.current?.click()}
        plain
        className="flex-grow bg-gradient-to-r from-st-blue to-st-green !text-white"
        disabled={props.disabled}
      >
        Upload receipt
      </Button>
      <input onChange={handleClick} multiple={false} ref={fileInputRef} type="file" hidden />
    </div>
  )
}

import { useState } from "react"

export default function useAsyncTask(): AsyncTask {
  const [progress, setProgress] = useState<AsyncTaskProgress | null>(null)
  const [error, setError] = useState<Error | null>(null)

  const cancel = () => {
    setProgress(null)
    setError(null)
  }

  return { progress, setProgress, cancel, error, setError }
}

export interface AsyncTask {
  progress: AsyncTaskProgress | null
  setProgress: (progress: AsyncTaskProgress) => void
  cancel: () => void
  error: Error | null
  setError: (error: Error | null) => void
}

interface AsyncTaskProgress {
  title: string
  subtitle: string
}

import Avatar from "@/components/avatar.tsx"
import { Input } from "@/components/input"
import { getExpenseItemNameGuesses, updateExpenseItem } from "@/services/expense-service"
import type { Expense, ExpenseItem, UserProfile } from "@/types.ts"
import { toCurrency } from "@/utils/string-utils.ts"
import AutoFixHighIcon from "@mui/icons-material/AutoFixHigh"
import { IconButton } from "@mui/material"
import CircularProgress from "@mui/material/CircularProgress"
import { type Variants, motion } from "framer-motion"
import {
  type KeyboardEventHandler,
  type RefObject,
  useCallback,
  useEffect,
  useRef,
  useState
} from "react"

type Props = {
  expense: Expense
  item: ExpenseItem
  users: UserProfile[] | null
  variants?: Variants
  onClick: () => void
}

export function ReceiptItem(props: Props) {
  const { name, total, userIds } = props.item
  const [updates, setUpdates] = useState<Partial<ExpenseItem> | null>(null)
  const isEditing = !!updates
  const splitCount = userIds?.length ?? 0
  const perPersonCost = Math.round((total / splitCount) * 100) / 100
  const splitUsers = props.item.userIds
    ?.map(id => props.users?.find(m => m.id === id))
    .filter(m => !!m)
  const quantity = props.item.quantity ?? 1

  const containerRef = useRef<HTMLLIElement>(null)

  const startEditing = () => setUpdates({})

  const saveEdits = () => {
    if (updates && Object.values(updates).length) {
      void updateExpenseItem(props.expense.id, props.item.id, updates)
    }
    setUpdates(null)
  }

  const updateEdits = (updates: Partial<ExpenseItem>) => {
    setUpdates(u => ({ ...u, ...updates }))
  }

  useOnClickOutside(containerRef, isEditing && saveEdits)

  const handleInputKeyDown: KeyboardEventHandler = evt => {
    if (evt.key === "Enter") {
      saveEdits()
    }
  }

  const [isGuessing, setIsGuessing] = useState(false)
  const [guesses, setGuesses] = useState<string[] | null>(null)

  const fetchNameGuesses = async () => {
    setIsGuessing(true)
    const guesses = await getExpenseItemNameGuesses(props.item.name, props.expense.name)
    setGuesses(guesses)
    setIsGuessing(false)
    console.log("guesses", guesses)
  }

  const handleGuessSelect = (guess: string) => {
    updateEdits({ name: guess })
    setGuesses(null)
  }

  return (
    <motion.li
      className="ml-4 list-none border-b last:border-b-0"
      variants={props.variants}
      ref={containerRef}
    >
      {isEditing && (
        <div className="flex flex-col gap-2 pr-4 pb-4">
          <div>
            <label>Name</label>
            <div className="flex gap-2">
              <Input
                value={updates?.name ?? name}
                onChange={evt => updateEdits({ name: evt.target.value })}
                onKeyDown={handleInputKeyDown}
              />
              <div className="flex items-center min-w-[40px] h-[40px]">
                {isGuessing ? (
                  <CircularProgress size={40} />
                ) : (
                  <IconButton onClick={fetchNameGuesses}>
                    <AutoFixHighIcon />
                  </IconButton>
                )}
              </div>
            </div>

            <ul className="list-disc ml-4">
              {guesses?.map(guess => (
                <li className="list-item py-2" key={guess} onClick={() => handleGuessSelect(guess)}>
                  {guess}
                </li>
              ))}
            </ul>
          </div>

          <div>
            <label>Total</label>
            <Input
              type="number"
              defaultValue={total}
              onChange={evt => updateEdits({ total: Number.parseFloat(evt.target.value) })}
              onKeyDown={handleInputKeyDown}
            />
          </div>
        </div>
      )}
      {!isEditing && (
        <div className="flex gap-2">
          <div className="py-2" onClick={startEditing}>
            <div>
              {quantity > 1 && `${quantity} `}
              {name}
            </div>
            <div className="text-sm text-gray-400">
              <span>{toCurrency(total)}</span>
              {splitCount > 1 && (
                <span>
                  {" • "}
                  {toCurrency(perPersonCost)}/person
                </span>
              )}
            </div>
          </div>

          <div className="flex justify-end items-center grow" onClick={props.onClick}>
            {splitCount === 0 && (
              <div className="flex items-stretch">
                <button type="button" className="text-st-blue px-4 py-2">
                  Claim
                </button>
              </div>
            )}

            {splitCount > 0 && (
              <div className="flex items-center pr-4">
                {splitUsers?.map(member => member && <Avatar user={member} key={member.id} />)}
              </div>
            )}
          </div>
        </div>
      )}
    </motion.li>
  )
}

function useOnClickOutside(
  ref: RefObject<HTMLElement>,
  handler: false | ((event: MouseEvent) => void)
) {
  const listener = useCallback(
    (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        event.stopPropagation()
        if (handler) handler(event)
      }
    },
    [ref, handler]
  )

  useEffect(() => {
    if (handler) {
      document.addEventListener("click", listener, { capture: true })
    } else {
      document.removeEventListener("click", listener, { capture: true })
    }

    return () => {
      document.removeEventListener("click", listener, { capture: true })
    }
  }, [handler, listener])
}

import CircularProgress from "@mui/material/CircularProgress"
import { motion } from "framer-motion"
import type { ReactNode } from "react"

type Props = {
  title: string
  subtitle: ReactNode
  icon?: ReactNode
  progress?: true | number
  actions?: ReactNode[]
}

const EmptyState = (props: Props) => (
  <div className="max-w-full overflow-hidden">
    <motion.div
      className="text-center px-4 "
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 0.5, ease: "easeInOut" } }}
      key={props.title}
    >
      {props.progress === true && (
        <div className="mx-auto h-12 w-12">
          <CircularProgress />
        </div>
      )}
      {props.icon && <div className="mx-auto text-gray-400">{props.icon}</div>}

      <motion.h3
        className="mt-2 text-m font-semibold text-gray-900"
        initial={{ opacity: 0, scale: 1.2 }}
        animate={{ opacity: 1, scale: 1, transition: { duration: 0.5, ease: "backOut" } }}
      >
        {props.title}
      </motion.h3>

      <motion.div
        className="inline-block mt-1 text-sm text-gray-500"
        initial={{ opacity: 0, y: -10 }}
        animate={{ opacity: 1, y: 0, transition: { duration: 0.5, delay: 0.5, ease: "backOut" } }}
      >
        {props.subtitle}
      </motion.div>

      {props.actions && <div className="mt-6 flex gap-2 justify-center">{props.actions}</div>}
    </motion.div>
  </div>
)

export default EmptyState
